import { IPersonaProps, IconButton, TooltipHost, mergeStyles } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import IAadUser from '../../utils/types/IAadUser';
import ErrorHandlingPeoplePicker from './ErrorHandlingPeoplePicker';
import searchForUsers from '../../utils/api/GraphUserApi';
import GraphUsersListDialog from './GraphUsersListDialog';
import CopyButton from '../../components/buttons/CopyButton';

export interface IGraphUsersPeoplePicker {
  id: string;
  label?: string;
  controlName?: string;
  onChange: (value: IAadUser[]) => void;
  errorMessage?: string;
  itemLimit?: number;
  defaultValue?: IAadUser[];
  removeButtonAriaLabel?: string;
  placeholder?: string;
  value?: IAadUser[];
  mustBeFte?: boolean;
  isMultiUserAddable?: boolean;
  width?: string;
  isRequired?: boolean;
}

interface ICustomUserArray {
  [index: string]: boolean;
}

export const mapUsersNoTypename = (users: IAadUser[]): IAadUser[] => {
  if (!users) return [];

  return users.map((user) => {
    return {
      graphGuid: user?.graphGuid,
      displayName: user?.isInvalidUser ? `(Invalid User) ${user?.displayName}` : user?.displayName,
      alias: user?.alias,
      emailAddress: user?.emailAddress,
    };
  });
};

const GraphUsersPeoplePicker = ({
  id,
  errorMessage,
  onChange,
  label,
  controlName,
  itemLimit,
  defaultValue,
  removeButtonAriaLabel,
  placeholder,
  value,
  mustBeFte = false,
  isMultiUserAddable = false,
  width,
  isRequired = false,
}: IGraphUsersPeoplePicker): JSX.Element => {
  const PICKER_RESOLVE_DELAY = 450;

  const [selectedPeople, setSelectedPeople] = useState<IAadUser[]>(
    mapUsersNoTypename(defaultValue) || [],
  );
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const onResolveSuggestions = (filterText: string): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      return searchForUsers(filterText, mustBeFte);
    }
    return [];
  };

  const getPersona = (users?: IAadUser[]): IPersonaProps[] => {
    if (!users || users.length === 0) {
      return [];
    }

    const results = users.map((user) => {
      return {
        id: user?.graphGuid?.toString(),
        text: user?.displayName,
        graphId: user?.graphGuid,
        alias: user?.alias,
        emailAddress: user?.emailAddress,
      };
    });

    return results;
  };

  useEffect(() => {
    if (value) {
      setSelectedPeople(mapUsersNoTypename(value));
    }
  }, [value]);

  const handleSubmitDialog = (data: IAadUser[]) => {
    const safeSelectedPeople = selectedPeople || [];
    const mergedPeopleList = [...safeSelectedPeople, ...data];

    const customUserArray: ICustomUserArray = {};

    const userListWithoutDuplicates = mergedPeopleList.filter((person) => {
      if (customUserArray[person.graphGuid]) {
        return false;
      }
      customUserArray[person.graphGuid] = true;
      return true;
    });

    setSelectedPeople(userListWithoutDuplicates);
    onChange(userListWithoutDuplicates);
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const className = mergeStyles({ width: width || 'auto' });

  const gridColumns = isMultiUserAddable ? 'auto auto' : 'auto';

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: gridColumns,
    gridAutoRows: 'auto',
  };

  return (
    <>
      <div className={className} style={gridStyle}>
        <div>
          <ErrorHandlingPeoplePicker
            removeButtonAriaLabel={removeButtonAriaLabel}
            controlName={controlName}
            labelText={label}
            inputProps={{
              id: `graphUsersPickers${id}`,
              'aria-label': label || 'Value',
              placeholder: placeholder || 'Search users',
              required: true,
            }}
            onChange={(items?: IAadUser[]): void => {
              if (!items) {
                setSelectedPeople([]);
                onChange([]);
                return;
              }

              const results = items.map((item) => {
                return {
                  graphGuid: item.id,
                  displayName: item.text,
                  alias: item.alias,
                  emailAddress: item.emailAddress,
                };
              });
              setSelectedPeople(results);
              onChange(results);
            }}
            selectedItems={getPersona(selectedPeople)}
            defaultSelectedItems={getPersona(selectedPeople)}
            onResolveSuggestions={onResolveSuggestions}
            resolveDelay={PICKER_RESOLVE_DELAY}
            itemLimit={itemLimit}
            errorMessage={errorMessage}
            required={isRequired}
          />
        </div>
        {isMultiUserAddable && (
          <div>
            <CopyButton data={defaultValue} />
            <TooltipHost content="Add multiple users to list">
              <IconButton
                iconProps={{ iconName: 'PeopleAdd' }}
                onClick={handleOpenDialog}
                aria-label="Add multiple users to list"
              />
            </TooltipHost>
          </div>
        )}
      </div>
      <GraphUsersListDialog
        onSubmit={handleSubmitDialog}
        onClose={handleCloseDialog}
        isOpen={isDialogOpen}
      />
    </>
  );
};

export default GraphUsersPeoplePicker;
